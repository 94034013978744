import PropTypes from 'prop-types';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../Form/Input/Input';
import Select from '../Form/Select/Select';
import styles from './TerminalPicker.module.scss';

function collectCountryOptionsFromTerminals(terminals) {
    const foundCountries = [];

    return terminals
        .reduce((carry, { country: { id, name } }) => {
            if (foundCountries.includes(id)) return carry;

            foundCountries.push(id);
            return [
                ...carry,
                {
                    value: id,
                    label: name,
                },
            ];
        }, [])
        .sort((a, b) => a.label.localeCompare(b.label));
}

const TerminalPicker = ({
    terminals = [],
    title,
    terminalLabel,
    onChange,
    values = {
        terminal: null,
        city: '',
    },
    errors = {
        terminal: '',
        city: '',
    },
}) => {
    const { t } = useTranslation();
    const [selectedCountryId, setSelectedCountryId] = useState(undefined);

    const countryOptions = useMemo(
        () => collectCountryOptionsFromTerminals(terminals),
        [terminals]
    );

    const terminalOptions = useMemo(
        () =>
            terminals.map(({ id, name, country }) => ({
                value: id,
                label: name,
                isDisabled: selectedCountryId && country.id !== selectedCountryId,
            })),
        [selectedCountryId, terminals]
    );

    useEffect(() => {
        // select country based on terminal
        if (values.terminal) {
            const { country: terminalCountry } = terminals.find(({ id }) => id === values.terminal);
            setSelectedCountryId(terminalCountry.id);
        }
    }, [values.terminal, terminals]);

    useEffect(() => {
        // clear country if city provided
        if (values.city) {
            setSelectedCountryId(undefined);
        }
    }, [values.city]);

    return (
        <section className={styles.TerminalPicker} data-testid='TerminalPicker'>
            <h1>{title}</h1>
            <Select
                label={t('enquiry.connectionLandLabel')}
                value={selectedCountryId}
                readonly={!!values.city}
                onChange={(value) => {
                    setSelectedCountryId(value);
                    onChange({
                        terminal: undefined,
                        city: values.city,
                    });
                }}
                options={countryOptions}
            />
            <Select
                label={terminalLabel}
                value={values.terminal}
                readonly={!!values.city}
                options={terminalOptions}
                onChange={(terminal) =>
                    onChange({
                        terminal: terminal,
                        city: terminal ? '' : values.city,
                    })
                }
                error={errors.terminal}
            />
            <span className={styles.Divider}>{t('generics.or')}</span>
            <Input
                label={t('enquiry.connectionCityLabel')}
                onChange={(city) =>
                    onChange({
                        terminal: city ? undefined : values.terminal,
                        city,
                    })
                }
                disabled={!!selectedCountryId || !!values.terminal}
                value={values.city}
                error={errors.city}
            />
        </section>
    );
};

TerminalPicker.propTypes = {
    terminals: PropTypes.arrayOf(
        PropTypes.shape({
            country: PropTypes.shape({
                id: PropTypes.string.isRequired,
            }),
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ),
    terminalLabel: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    values: PropTypes.shape({
        terminal: PropTypes.string,
        city: PropTypes.string,
    }),
    errors: PropTypes.shape({
        terminal: PropTypes.string,
        city: PropTypes.string,
    }),
};

export default TerminalPicker;
