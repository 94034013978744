import graphql from 'babel-plugin-relay/macro';
import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import Component from './TerminalPicker';

export const TerminalsQuery = graphql`
    query TerminalPickerTerminalsQuery($country: String) {
        terminals(country: $country) {
            id
            name
            country {
                id
                name
            }
        }
    }
`;

const TerminalPickerLoader = (props) => {
    const { terminals } = useLazyLoadQuery(TerminalsQuery, {});

    return <Component terminals={terminals} {...props} />;
};

const TerminalPicker = ({
    onChange,
    values = {
        terminal: null,
        city: '',
    },
    errors = {
        terminal: '',
        city: '',
    },
    ...props
}) => (
    <Suspense
        fallback={
            <Component
                terminals={[]}
                onChange={onChange}
                errors={errors}
                values={values}
                {...props}
            />
        }
    >
        <TerminalPickerLoader onChange={onChange} errors={errors} values={values} {...props} />
    </Suspense>
);

TerminalPicker.propTypes = {
    onChange: PropTypes.func,
    values: PropTypes.shape({
        terminal: PropTypes.string,
        city: PropTypes.string,
    }),
    errors: PropTypes.shape({
        terminal: PropTypes.string,
        city: PropTypes.string,
    }),
};

export default TerminalPicker;
