import { useEffect, useRef } from 'react';

export default function MatomoProvider({ children }) {
    const ref = useRef(false);
    useEffect(() => {
        if (ref.current) {
            return; // Only load once
        }
        ref.current = true;
        const matomoUrl = (process.env.REACT_APP_MATOMO_URL || '').replace(/\/$/, '');
        const containerId = process.env.REACT_APP_MATOMO_CONTAINER_ID || '';
        console.log({ matomoUrl, containerId });
        if (!matomoUrl || !containerId) {
            return;
        }
        let _mtm = (window._mtm = window._mtm || []);
        _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
        let d = document,
            g = d.createElement('script'),
            s = d.getElementsByTagName('script')[0];
        g.async = true;
        g.src = `${matomoUrl}/js/container_${containerId}.js`;
        s.parentNode.insertBefore(g, s);
    }, []);

    return <>{children}</>;
}
